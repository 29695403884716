<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Altri Ricavi"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
      <v-btn
        icon
        @click="toggleBarcode"
        :color="!!ghost ? 'red' : ''"
      >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </template>

    <template v-slot:content>
      <div style="width: 100%;">
        <AdvancedFilter
          style="width:99%; height: 100%; margin-bottom: 8px;"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:custom-recipient="{filter} ">
            <div class="d-flex ml-2">
              <v-btn-toggle
                v-model="filter.value.type"
                rounded
                class="pa-1"
                mandatory
              >
                <v-btn>
                  <v-icon>mdi-account</v-icon>
                </v-btn>
                <v-btn>
                  <v-icon>mdi-domain</v-icon>
                </v-btn>
              </v-btn-toggle>
              <CustomersAutocomplete
                v-if="!filter.value.type"
                style="min-width: 85%"
                class="pr-5" 
                return-object
                @input="(res) => { filter.value.id = res.id }" 
                append-icon="mdi-account"
              ></CustomersAutocomplete>
              <SuppliersAutocomplete
                v-else-if="!!filter.value.type"
                style="min-width: 85%"
                class="pr-5" 
                return-object
                @input="(res) => { filter.value.id = res.id }"  
                append-icon="mdi-domain"
              ></SuppliersAutocomplete> 
            </div>  
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 87%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento altri ricavi..."
          :headers="headers"
          :loading="loading"
          :items="otherCashes"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          @edit="handleEdit"
          @delete="handleDelete"
          @dblclick="handleEdit"
          :show-select="false"
        >
          <template v-slot:custom-recipient="{ item }">
            <span v-if="!!item.customer">{{ item.customer.firstname + ' ' + item.customer.lastname }}</span>
            <span v-else-if="!!item.supplier">{{ item.supplier.businessName }}</span>
          </template>
          <template v-slot:externalFooter>
            <TypeDataTable
              :headers="totalHeader"
              :items="totalRow"
              :show-select="false"
              :show-actions="false"
              disablePagination
            >
            </TypeDataTable>
          </template>
        </TypeDataTable>
      </div>
      <StandardDialog
        v-model="dialogBarcode"
        title="Area Riservata Sviluppatori" 
        :dialog-height="null"
        dialog-max-width="500px"
      >
        <ManualBarcodeInput
          v-model="ghostBarcode"
          scan-your-barcode-message="Immettere Codice" 
          @confirm="activeGhost"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import otherCashesService from '@/services/otherCashes/otherCashes.service.js'
import operatorService from '@/services/operators/operators.service.js'
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

import CustomersAutocomplete from "@/components/common/CustomersAutocomplete.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";

export default {
  name: "OtherCashesList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    StandardMenu,
    AdvancedFilter,
    StandardDialog,
    ManualBarcodeInput,
    ExportExcelTable,
    CustomersAutocomplete,
    SuppliersAutocomplete
  },
  mixins: [dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Payments'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      otherCashes: [],
      headers: [],
      page: 1,
      rowPerPage: 200,
      totalPages: 0,
      resizeListener: undefined,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'emittedAt', name: 'Data Emissione', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'date', operator: 'between', field: 'expirationDate', name: 'Data Scadenza', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'string', operator: 'equal', field: 'documentNumber', name: 'Numero Documento', label: 'Numero Documento', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'amount', name: 'Importo', label: 'Importo', color: "", value: undefined },
        { type: 'custom', operator:'custom', field: 'recipient', name: 'Mittente', label: 'Mittente', color:"", value: { type: 0 }, blank: { type: 0 }, icon: 'mdi-domain'},
        { type: 'string', operator: 'like', field: 'internalCode', name: 'Codice', label: 'Codice', color: "", value: undefined }
      ],
      advanceFilters: [
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data Creazione', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'string', operator: 'like', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined },
      ],
      externalFilter: { type: 'string', operator: 'like', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined },
      filtersValue: [
      ],
      totalHeader: [
        { text: 'N°', value: 'count' },
        { text: 'Importo', value: 'sum', type: 'price' },
      ],
      totalRow: [],
      ghost: false,
      dialogBarcode: false,
      ghostBarcode: undefined,
      worksheetName: "Lista Altri Incassi",
      fileName: "",
      fieldsToExport: {},
      isLicenseBlocked: undefined,
    }
  },
  props: {
    filters: {
      type: Array,
      default: function() {
        return [
        ]
      }
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchOtherCashes()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 150
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 150
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)

    this.fileName = "Altri_Incassi_" + this._buildDateToExport(new Date()) +
      ".xls";

    this.fieldsToExport = otherCashesService._fieldsToExport()
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchOtherCashes() {
      this.loadingItems = true

      let filters = [...this.filtersValue, { operator: 'custom', field: 'ghost', value: this.ghost }]
      otherCashesService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page && this.totalPages != 0) {
          this.page = this.totalPages
        }

        this.otherCashes = results.rows
        this.totalRow = results.totalRow 
        this.loadingItems = false
      })
    },
    async fetchExcel(){
      let filters = [...this.filtersValue, { operator: 'custom', field: 'ghost', value: this.ghost }]
      let result = await otherCashesService.list(this.page, 15000, filters)

      let returnRow = {
        description: "TOTALE",
        amount: result.totalRow[0].sum
      }

      return [...result.rows, returnRow]
    },
    fetchHeaders() {
      this.loadingHeaders = true
      otherCashesService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(otherCash) {
      this.$router.push({
        name: 'OtherCashesEditForm',
        params: {
          id: otherCash.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'OtherCashesList'}).href,
          pathName: 'OtherCashesList'
        }
      })
    },
    handleDelete(otherCash) {
      let confirmed = confirm("Sei sicuro di voler eliminare l'incasso?")

      if(confirmed) {
        this.loading
        otherCashesService.delete(otherCash).then(() => {
          this.fetchOtherCashes()
          this.loading
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'OtherCashesNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'OtherCashesList'}).href
        }
      })
    },
    applyFilters() {
      this.fetchOtherCashes()
    },
    toggleBarcode() {
      this.dialogBarcode = true
    },
    activeGhost(){
      operatorService.canPerformOperation(this.ghostBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost      
          this.applyFilters()
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch(() => {
        alert("Badge non riconosciuto")
      })
      this.ghostBarcode = undefined
      this.dialogBarcode = false
    },
  },
  computed: {
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchOtherCashes()
    },
    rowPerPage() {
      this.fetchOtherCashes()
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>